// src/pages/AgentList/ListPage/ListPage.js

import React from 'react';
import EmployeeTable from './component/EmployeeTable';

export const ListPage = ({ setSelectedEmployee }) => {
    return (
        <div style={{ width: "100%" }}>
            <h3 style={{ marginTop: 16 }}>员工列表</h3>
            <EmployeeTable setSelectedEmployee={setSelectedEmployee} /> {/* 修正 Prop 名称 */}
        </div>
    )
}