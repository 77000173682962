import React, { useState, useEffect, useRef } from "react";
import { Button, Message, Space, Table } from "@arco-design/web-react";
import {
  addCaseApi,
  addCaseAssistantApi,
  deleteCaseApi,
  getAssistantCasesApi,
  getCasesApi,
  updateCaseApi,
} from "../../../api/normalApi";
import PropTypes from "prop-types";
import CaseCardModal from "./CaseCardModal";
import CaseMaterialModal from "./CaseMaterialModal";

const CaseMaterial = ({
  columns,
  tableWidth,
  assistant_id = null,
  setCases,
  mode,
}) => {
  const [caseList, setCaseList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const editMode = useRef("");
  const [currentCase, setCurrentCase] = useState({});
  const [caseListVisible, setCaseListVisible] = useState(false); //勾选对话是否可见

  useEffect(() => {
    if (!caseListVisible) {
      setTimeout(() => getCases(), 5000);
    }
    // eslint-disable-next-line
  }, [caseListVisible]);

  // 获取案例列表
  const getCases = async () => {
    try {
      let data;
      if (assistant_id) {
        const response = await getAssistantCasesApi(assistant_id);
        if (response.status !== "success") {
          console.log("Failed to fetch assistant cases");
        }
        data = response.data.map((item) => item.case);
        const filteredCases = data.map((item) => ({
          case_name: item.case_name,
          description: item.description,
          file_url: item.file_url,
          openai_file_id: item.openai_file_id,
          user_id: item.user_id,
        }));
        setCases(filteredCases);
      } else {
        const response = await getCasesApi();
        if (response.status !== "success") {
          throw new Error("Failed to fetch products");
        }
        data = response.data;
      }
      const casesWithKey = data.map((item, index) => ({
        ...item,
        key: index + 1,
      }));
      setCaseList(casesWithKey);
    } catch (error) {
      Message.error("获取案例列表失败，请稍后重试");
    }
  };

  const extractFileName = (filePath) => {
    if (!filePath) return ""; // 如果路径为空，返回空字符串

    // 使用正则表达式提取最后一个斜杠后面的部分
    return filePath.split("/").pop();
  };

  useEffect(() => {
    getCases();
    // eslint-disable-next-line
  }, []);

  // 新增案例行
  const handleAdd = () => {
    setModalVisible(true);
    editMode.current = "add";
    setCurrentCase("");
  };

  // 编辑案例
  const handleEdit = (record) => {
    setModalVisible(true);
    editMode.current = "edit";
    setCurrentCase(record);
  };

  // 删除案例
  const handleDelete = async () => {
    if (currentCase && currentCase.id) {
      const response = await deleteCaseApi(currentCase.id);
      if (response.status === "success") {
        Message.success("删除案例成功");
        setModalVisible(false);
        await getCases();
      } else {
        Message.error("删除案例失败");
        setModalVisible(false);
      }
    }
  };

  // 保存案例
  const handleSave = async (updatedCase) => {
    if (editMode.current === "add") {
      addCaseApi({ ...updatedCase }).then((response) => {
        if (response.status === "success") {
          // 如果有 assistant_id，则调用绑定商品和助手api
          if (assistant_id) {
            const data = [
              {
                case_id: response.data.id,
                assistant_id: assistant_id,
              },
            ];
            addCaseAssistantApi(data).then((bindResponse) => {
              if (bindResponse.status !== "success") {
                Message.error("绑定案例和助手失败");
              } else {
                getCases().then(() => {
                  Message.success("新增案例成功");
                });
              }
            });
          } else {
            getCases().then(() => {
              Message.success("新增案例成功");
            });
          }
        } else {
          Message.error("新增案例失败");
        }
      });
    } else if (editMode.current === "edit") {
      const response = await updateCaseApi(updatedCase.id, updatedCase);
      if (response.status === "success") {
        Message.success("修改案例成功");
        await getCases();
      }
    }
    setModalVisible(false);
  };

  // 定义所有可能的列
  const allColumns = [
    {
      title: "编号",
      dataIndex: "key",
      width: 70,
    },
    {
      title: (
        <Space>
          资料名称
          {mode === "displayModal" && (
            <Button type="text" onClick={() => setCaseListVisible(true)}>
              管理
            </Button>
          )}
        </Space>
      ),
      dataIndex: "case_name",
      width: 180,
    },
    {
      title: "文件描述",
      dataIndex: "description",
    },
    {
      title: "案例文件",
      dataIndex: "file_url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text ? extractFileName(text) : "暂无文件"}
        </a>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button size={"small"} type="primary" onClick={handleAdd}>
            新建
          </Button>
        </div>
      ),
      dataIndex: "actions",
      align: "right", // 将这一列内容向右对齐
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          <Button size={"small"} onClick={() => handleEdit(record)}>
            编辑
          </Button>
        </div>
      ),
    },
  ];

  const displayedColumns = columns
    ? allColumns.filter((col) => columns.includes(col.dataIndex))
    : allColumns;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <CaseMaterialModal
        caseListVisible={caseListVisible}
        setCaseListVisible={setCaseListVisible}
        assistant_id={assistant_id}
      />
      <Table
        size={"small"}
        columns={displayedColumns}
        data={caseList}
        pagination={false}
        rowKey="key"
        style={{ minWidth: tableWidth }}
      />
      <CaseCardModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        caseData={currentCase}
        setCaseData={setCurrentCase}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />
    </Space>
  );
};

CaseMaterial.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  tableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assistant_id: PropTypes.string,
};
// // 设置默认Props
// CaseCardModal.defaultProps = {
//     assistant_id: null,
// };
export default CaseMaterial;
