import React, { useEffect, useMemo, useRef, useState } from "react";
import localforage from "localforage";
import {
  Avatar,
  Badge,
  Input,
  List,
  Space,
  Spin,
  Menu,
  Dropdown,
  Typography,
  Button,
} from "@arco-design/web-react";
import { getWxUsersApi, getContactsApi } from "../../../api/normalApi";
import UserChat from "./UserChat";
import { getThreadMessages } from "../../../api/openapi";
import { isToday } from "../../../utils/format";
import { IconPlus } from "@arco-design/web-react/icon";
import UserManagement from "../Modal/UserManagementModal";

const { Search: InputSearch } = Input;

const ChatList = ({ currentWxAccount, setThreadId, assistant }) => {
  const [chatList, setChatList] = useState([]); // 存储用户数据
  const [loading, setLoading] = useState(true); // 加载状态
  const [messages, setMessages] = useState([]); // 当前聊天的消息
  const [imageUrls, setImageUrls] = useState({}); // 存储图片 URL
  const currentWxUser = useRef(null); // 当前选中的用户
  const [lastMessages, setLastMessages] = useState({}); // 存储每个用户的最后一条消息
  const [timestampList, setTimestampList] = useState([]); //每条最后消息的时间戳
  const wxUserUnreadList = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [manageVisible, setManageVisible] = useState(false);
  const [users, setUsers] = useState([]); // 传递给userModal的列表
  const [currentAutoReply, setCurrentAutoReply] = useState(false); //选中对话的auto_reply
  const autoReplyList = useMemo(() => { //回复列表
    return chatList.map(chatItem => {
      const matchedUser = users.find(user => user.to_user_id === chatItem.to_user_id);
      return matchedUser ? !!matchedUser.auto_reply : true;  //默认值设为true
    });
  }, [users, chatList]);

  useEffect(() => {//获取消息列表的开启
    if (currentWxAccount) {
      getContactsApi(currentWxAccount.wx_user_id).then((res) => {
        if (res && res.contacts && res.contacts.length > 0) {
          const processedUsers = res.contacts.map((item) => ({
            ...item,
          }));
          setUsers(processedUsers);
          if (currentWxUser.current) {
            const matchedUser = processedUsers.find(
              user => user.to_user_id === currentWxUser.current.to_user_id
            );
            setCurrentAutoReply(!!matchedUser?.auto_reply);
          }
        }
      });
    }
  }, [currentWxAccount, currentAutoReply]);

  // 定义一个截断文本的函数
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  // 获取特定线程的消息
  const getMessageList = async (threadId) => {
    try {
      return await getThreadMessages(threadId);
    } catch (e) {
      console.error(`Error fetching messages for thread_id ${threadId}:`, e);
      return null;
    }
  };

  //找到未读的第一条消息
  const findFirstReadTimeMessage = (messages) => {
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].metadata && messages[i].metadata.readTime) {
        return i; // 返回第一个匹配的索引
      }
    }
    return -1; // 如果没有找到，返回 -1
  };

  // 获取用户及其最后一条消息
  const fetchUsers = async () => {
    try {
      const response = await getWxUsersApi(
        String(currentWxAccount.wx_user_id),
        true,
      );
      if (response.status === 200) {
        const users = response.data;
        setChatList(users);

        // 获取所有用户的最后一条消息
        const fetchLastMessages = async () => {
          try {
            const promises = users.map((user) =>
              getMessageList(user.thread_id),
            );
            const results = await Promise.all(promises);
            const newLastMessages = {};
            let unReadMessageCount = [];
            const newLastTimestamp = {};
            results.forEach((result, index) => {
              const user = users[index];
              unReadMessageCount.push(findFirstReadTimeMessage(result.data));
              if (result && result.data && result.data.length > 0) {
                // 假设最新的消息在数组的第一个元素
                const lastMessageObj = result.data[0];
                // 检查 content 数组是否存在且有元素
                if (
                  lastMessageObj.content &&
                  lastMessageObj.content.length > 0
                ) {
                  // 当前信息时间戳
                  if (lastMessageObj.created_at) {
                    newLastTimestamp[user.thread_id] =
                      lastMessageObj.created_at;
                  }
                  // 查找包含 text 的对象
                  const textObj = lastMessageObj.content.find(
                    (item) => item.text,
                  );
                  if (textObj && textObj.text && textObj.text.value) {
                    newLastMessages[user.thread_id] = textObj.text.value;
                  } else {
                    newLastMessages[user.thread_id] = "[无效消息格式]";
                  }
                } else {
                  newLastMessages[user.thread_id] = "[无消息内容]";
                }
              } else {
                newLastMessages[user.thread_id] = "暂无消息";
              }
            });
            wxUserUnreadList.current = unReadMessageCount;
            setLastMessages(newLastMessages);
            setTimestampList(newLastTimestamp);
          } catch (error) {
            console.error("Error fetching last messages:", error);
          }
        };

        await fetchLastMessages();

        // 如果没有选中的用户，设置第一个用户为默认
        if (users.length > 0) {
          if (currentWxUser.current === null) {
            const firstUser = users[0];
            currentWxUser.current = firstUser;
            await localforage.setItem(
              `${currentWxAccount.wx_user_id}-currentWxUser`,
              firstUser,
            );
            const data = await getMessageList(firstUser.thread_id);
            if (data && data.data) {
              setMessages(data.data);
            }
          } else {
            const data = await getMessageList(currentWxUser.current.thread_id);
            if (data && data.data) {
              setMessages(data.data);
            }
          }
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false); // 结束加载状态
    }
  };

  // 每 10 秒获取一次用户列表
  useEffect(() => {
    const initCurrentWxUser = async () => {
      try {
        const storedData = await localforage.getItem(
          `${currentWxAccount.wx_user_id}-currentWxUser`,
        );
        currentWxUser.current =
          storedData && storedData !== "undefined" ? storedData : null;
      } catch (error) {
        console.error("Error retrieving currentWxUser:", error);
      }
    };

    if (currentWxAccount) {
      initCurrentWxUser();
      fetchUsers();
    }

    const intervalId = setInterval(() => {
      if (currentWxAccount) {
        fetchUsers();
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [currentWxAccount]);

  // 每 5 秒获取当前用户的消息
  useEffect(() => {
    if (currentWxUser.current) {
      setThreadId(currentWxUser.current.thread_id);
      const messageIntervalId = setInterval(async () => {
        const data = await getMessageList(currentWxUser.current.thread_id);
        if (data && data.data) {
          setMessages(data.data);
        }
      }, 5000);

      return () => clearInterval(messageIntervalId);
    }
    // eslint-disable-next-line
  }, [currentWxUser.current]);

  if (loading) {
    return (
      <Space
        align="center"
        style={{ width: "100%", height: "100%", justifyContent: "center" }}
      >
        <Spin dot /> {/* 加载动画 */}
      </Space>
    );
  }

  const handleItemClick = async (item, mode) => {
    if (mode === "filter") {
      setSearchTerm(item.nickname);
    } else {
      setSearchTerm("");
      await localforage.setItem(
        `${currentWxAccount.wx_user_id}-currentWxUser`,
        item,
      );
    }
    const matchedUser = users.find(user => user.to_user_id === item.to_user_id);
    setCurrentAutoReply(!!matchedUser?.auto_reply);

    setMessages([]);
    currentWxUser.current = item;
    const data = await getMessageList(item.thread_id);
    if (data && data.data) {
      setMessages(data.data);
    }
  };

  // 筛选符合搜索条件的 chatList
  const filteredChatList = chatList.filter((item) =>
    item.nickname.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // 创建下拉菜单项
  const dropList = (
    <Menu style={{ width: 280 }}>
      {filteredChatList.map((item) => (
        <Menu.Item
          key={item.thread_id}
          onClick={() => handleItemClick(item, "filter")} // 点击时更新当前用户
        >
          {item.nickname}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Space align={"start"}>
      <Space direction={"vertical"}>
        <Space style={{ width: 300 }}>
          <Dropdown droplist={dropList} position="bl" trigger="click">
            <InputSearch
              allowClear
              placeholder="搜索聊天"
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              style={{ width: 260 }}
            />
          </Dropdown>
          <Dropdown
            droplist={
              <Menu style={{ width: 100 }}>
                <Menu.Item key="1" onClick={() => setManageVisible(true)}>
                  管理回复
                </Menu.Item>
                <Menu.Item key="2">拉群聊天</Menu.Item>
              </Menu>
            }
            position="bl"
            trigger="click"
          >
            <Button type="primary" icon={<IconPlus />} />
          </Dropdown>
        </Space>
        {/*聊天列表*/}
        <List
          style={{
            width: 300,
            height: "88vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "#EEE white",
          }}
          dataSource={chatList}
          render={(item, index) => (
            <List.Item
              key={item.thread_id}
              style={{
                backgroundColor: item.thread_id === currentWxUser.current?.thread_id ? "#366EF415" : "",
                cursor: "pointer",
                position: 'relative'
              }}
            >
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: autoReplyList[index] ? '#00B42A' : '#FF7D00',
                  position: 'absolute',
                  left: 4,
                  top: 4,
                  zIndex: 1
                }}
              />
              <List.Item.Meta
                onClick={() => handleItemClick(item)}
                style={{ marginLeft: '4px' }}
                avatar={
                  <div>
                    <Badge count={wxUserUnreadList.current[index]}>
                      <Avatar
                        shape="square"
                        style={{ backgroundColor: "#3370ff" }}
                      >
                        {item.head_img ? (
                          <img
                            alt="头像"
                            src={`data:image/png;base64,${item.head_img}`}
                          />
                        ) : (
                          item.nickname.charAt(0)
                        )}
                      </Avatar>
                    </Badge>
                  </div>
                }
                title={
                  <Space align={"center"}>
                    <Typography.Ellipsis style={{ width: 128 }}>
                      {item.nickname}
                    </Typography.Ellipsis>
                    <div
                      style={{
                        color: "#86909C",
                        fontSize: "13px",
                        width: 50,
                        textAlign: "right",
                      }}
                    >
                      {isToday(timestampList[item.thread_id])}
                    </div>
                  </Space>
                }
                description={
                  <Typography.Ellipsis style={{ width: 190 }}>
                    {lastMessages[item.thread_id]}
                  </Typography.Ellipsis>
                }
              />
            </List.Item>
          )}
        />
      </Space>
      <UserChat
        currentWxUser={currentWxUser.current}
        currentWxMyAccount={currentWxAccount}
        messages={messages}
        setMessages={setMessages}
        imageUrls={imageUrls}
        setImageUrls={setImageUrls}
        getMessageList={getMessageList}
        assistant={assistant}
        autoReply={currentAutoReply}
        setAutoReply={setCurrentAutoReply}
      />
      <UserManagement
        visible={manageVisible}
        setVisible={setManageVisible}
        users={users}
        wxId={currentWxAccount.wx_user_id}
      />
    </Space>
  );
};

export default ChatList;
