import React, { useEffect, useState } from "react";
import {
  Input,
  List,
  Switch,
  Avatar,
  Typography,
  Modal,
  Space,
} from "@arco-design/web-react";
import { setAutoReplyForUser } from "../../../api/normalApi";

const { Search } = Input;
const { Text } = Typography;

export const UserManagement = ({ visible, setVisible, users, wxId }) => {
  const [userList, setUserList] = useState(users);

  const handleToggle = (item) => {
    const updatedUserList = userList.map((user) =>
      user.to_user_id === item.to_user_id
        ? { ...user, auto_reply: !user.auto_reply }
        : user,
    );
    setUserList(updatedUserList);
    let back_data = {
      wx_user_id: wxId,
      nickname: item.nickname,
      auto_reply: item.auto_reply ? "false" : "true",
    };
    setAutoReplyForUser(back_data).then((res) => {
      if (res.status !== 200) {
        console.log(res);
      }
    });
  };

  useEffect(() => {
    setUserList(users);
  }, [users]);

  const handleSearch = (value) => {
    const filteredUsers = users.filter((user) =>
      user.nickname.toLowerCase().includes(value.toLowerCase()),
    );
    setUserList(filteredUsers);
  };

  return (
    <Modal
      style={{ padding: "20px" }}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
    >
      <Search
        placeholder="搜索聊天"
        style={{ marginBottom: "16px", width: "100%" }}
        onChange={(v) => handleSearch(v)} // 监听输入框变化
      />
      <List
        dataSource={userList}
        render={(item) => (
          <List.Item
            key={item.to_user_id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Space style={{ display: "flex", alignItems: "center" }}>
                <Avatar size={40}>
                  <img alt="avatar" src={item.head_url} />
                </Avatar>
                <Text style={{ marginLeft: "12px" }}>{item.nickname}</Text>
              </Space>
              <Switch
                checked={item.auto_reply}
                onChange={() => handleToggle(item)}
                checkedText="开启"
                uncheckedText="关闭"
              />
            </Space>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default UserManagement;
