// src/pages/AgentList/ListPage/component/EmployeeTable.js

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  Switch,
  Space,
  Button,
  Message,
  Modal,
  Popconfirm,
} from "@arco-design/web-react";
import { IconWechat } from "@arco-design/web-react/icon";
import {
  addAssistantApi, // 添加员工的接口
  getAssistantsWithWeChatApi,
  logoutWechatApi,
  updateWechatAutoReplyApi,
  deleteAssistantApi,
  loginWechatApi,
  getAssistantProductsApi,
  addProductAssistantApi,
  getAssistantCasesApi,
  addCaseAssistantApi,
  getWeChats,
  cancelWechatApi,
} from "../../../../api/normalApi";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

//TODO:合并？
const EmployeeTable = ({ setSelectedEmployee }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const maxSeats = 15; // 最大员工数
  const navigate = useNavigate(); // 初始化 useNavigate
  const [qrCode, setQrCode] = useState(null);
  const [intervalActive, setIntervalActive] = useState(false);
  const [previousUpdatedAt, setPreviousUpdatedAt] = useState({}); // 存储每个账号的 updated_at
  const [isLoggingIn, setIsLoggingIn] = useState(false); // 标识是否有登录正在进行
  const wxInstance = useRef();

  const handleDeleteAssistant = async (assistantId) => {
    Modal.confirm({
      title: "确认删除该员工吗？",
      content: (
        <div style={{ textAlign: "center" }}>
          <p>删除后，该员工的所有信息将无法恢复</p>
        </div>
      ),
      onOk: async () => {
        try {
          const data = await deleteAssistantApi(assistantId);
          if (data.status === 200 || data.status === "success") {
            await getAssistantsWxIds(); // 重新获取员工列表
            Message.success("删除员工成功");
          } else {
            Message.error("删除员工失败，请稍后重试");
          }
        } catch (error) {
          Message.error("删除员工失败，请稍后重试");
        }
      },
    });
  };

  // 处理员工和微信账号数据
  const processEmployeeData = (data, unboundedData) => {
    if (data === null) return [];
    let result = [];
    data.forEach((employee) => {
      if (employee.wechats.length === 0) {
        // 查找 unboundedData 中与当前 employee 匹配的记录
        const unbounded = unboundedData.find(
          (unbound) => unbound.assistant_id === employee.assistant.id,
        );

        result.push({
          ...employee,
          id: employee.assistant.id, // 确保有 id 字段
          name: employee.assistant.name,
          wx_id: null,
          nickname: "未绑定",
          auto_reply: unbounded ? unbounded.auto_reply : false,
          is_online: unbounded ? unbounded.is_online : false,
        });
      } else {
        employee.wechats.forEach((wechat, index) => {
          result.push({
            ...wechat,
            ...employee,
            id: employee.assistant.id, // 确保有 id 字段
            name: index === 0 ? employee.assistant.name : "",
            wx_id: wechat.wx_id,
            nickname: wechat.nickname,
            auto_reply: wechat.auto_reply,
            is_online: wechat.is_online,
          });
        });
      }
    });

    // 处理没有绑定助手的微信账号
    unboundedData.forEach((unbound) => {
      // 检查 unbound 是否在已有绑定的 employee 数据中
      const isBound = data.some(
        (employee) => employee.assistant.id === unbound.assistant_id,
      );
      if (!isBound) {
        result.push({
          assistant: "",
          wechats: [
            {
              wx_id: unbound.wx_id,
              nickname: unbound.nickname,
              auto_reply: unbound.auto_reply,
              is_online: unbound.is_online,
            },
          ],
          id: `unattached-${unbound.wx_id}`,
        });
      }
    });
    return result;
  };

  // 获取员工及微信账号数据
  const getAssistantsWxIds = async () => {
    // 判断是否有现有账号的 updated_at 变化
    const updatedAtChanged = employeeList.some((account) => {
      const previous = previousUpdatedAt[account.wx_id];
      return previous && previous !== account.updated_at;
    });

    if (updatedAtChanged) {
      // 登录成功，已有账号的 updated_at 发生变化
      setIntervalActive(false);
      setQrCode(null);
      setIsLoggingIn(false);
      setPreviousUpdatedAt(
        employeeList.reduce((acc, account) => {
          acc[account.wx_id] = account.updated_at;
          return acc;
        }, {}),
      );
    }

    try {
      const { data: assistantData } = await getAssistantsWithWeChatApi(); // 调用新接口获取数据
      const { data: unboundedData } = await getWeChats();
      const processedData = processEmployeeData(assistantData, unboundedData); // 处理数据

      setEmployeeList(processedData);
    } catch (error) {
      Message.error("获取员工及微信账号信息失败");
      console.log(error);
    }
  };

  useEffect(() => {
    getAssistantsWxIds(); // 获取数据
  }, []);

  // 设置轮询
  useEffect(() => {
    let intervalId;
    if (intervalActive) {
      intervalId = setInterval(async () => {
        await getAssistantsWxIds();
      }, 5000); // 将轮询间隔设置为5秒
    }

    return () => clearInterval(intervalId);
  }, [intervalActive, getAssistantsWxIds]);

  // 更新自动回复开关
  const handleSwitchChange = async (wxID, newAutoReplyStatus) => {
    try {
      let data = {
        wx_user_id: wxID,
        auto_reply: newAutoReplyStatus,
      };
      await updateWechatAutoReplyApi(data);

      // 更新状态
      const updatedList = employeeList.map((item) =>
        item.wx_id === wxID
          ? { ...item, auto_reply: newAutoReplyStatus }
          : item,
      );
      setEmployeeList(updatedList);
      Message.success("自动回复状态更新成功");
    } catch (error) {
      Message.error("更新自动回复状态失败，请稍后重试");
    }
  };

  // 选择员工，点击“编辑”按钮时调用
  const handleSelectedEmployee = (row) => {
    setSelectedEmployee(row); // 传递选中的员工数据
    navigate(`/employees/${row.id}`); // 跳转到编辑路由，并传递员工ID
  };

  // 确认退出微信
  const confirmLogout = (accountId) => {
    Modal.confirm({
      title: "确认退出登录吗？",
      content: (
        <div style={{ textAlign: "center" }}>
          <p>退出登录微信后将不再接收和回复消息，且绑定的助手也将解绑</p>
        </div>
      ),
      onOk: async () => {
        logoutWx(accountId);
      },
    });
  };

  //登出微信
  const logoutWx = useCallback(async (accountId) => {
    logoutWechatApi({ wx_user_id: accountId }).then(async (data) => {
      if (data.status === 200) {
        await getAssistantsWxIds();
        Message.success("退出登录成功");
      }
    });
  }, []);

  // 添加员工
  const handleAddEmployee = async () => {
    const remainingSeats = maxSeats - employeeList.length; // 剩余席位
    if (remainingSeats > 0) {
      const newEmployee = {
        name: `新员工`,
        account: null,
        auto_reply: false,
        instructions: "测试",
        sales_process: "{}",
      };

      try {
        const data = await addAssistantApi(newEmployee); // 调用添加员工 API
        if (data.status === "success") {
          await getAssistantsWxIds(); // 刷新员工列表
          Message.success(`已添加 ${newEmployee.name}`);
        } else {
          Message.error("添加员工失败，请稍后重试");
        }
      } catch (error) {
        Message.error("添加员工失败，请稍后重试");
      }
    } else {
      Message.warning("已无剩余席位");
    }
  };

  // 登录函数
  const handleLogin = async () => {
    setIsLoggingIn(true); // 设置登录状态为进行中

    try {
      const data = await loginWechatApi();
      if (data && data.data && data.data.qr_code) {
        setQrCode(data.data.qr_code);
        wxInstance.current = data.data.wx_instance_name;
        setIntervalActive(true);
      } else {
        console.log("没有获取到登录数据");
        Message.error("无法获取二维码，请重试");
      }
    } catch (err) {
      console.log(err);
      Message.error("登录失败，请重试");
    }
  };

  const cancelLogin = () => {
    cancelWechatApi({ wx_instance_name: wxInstance.current }).then((res) => {
      if (res.status !== 200) {
        console.log(res);
      }
    });
    setIntervalActive(false);
  };

  // 复制助手
  const handleCopy = async (row) => {
    const back_data = {
      name: row.assistant.name + "-副本",
      instructions: row.assistant.instructions,
      sales_process: row.assistant.sales_process,
      draft_instructions: row.assistant.draft_instructions,
      role_instructions: row.assistant.role_instructions,
    };
    let assistantId;
    const res = await addAssistantApi(back_data);
    if (res.status === "success") {
      assistantId = res.data.id;
    } else {
      Message.error("复制助手失败");
      console.log(res);
      return; // 失败时提前返回
    }
    Promise.all([
      getAssistantProductsApi(row.id).then((res) => {
        const productArray = res.data.map((item) => ({
          product_id: item.product_id,
          assistant_id: assistantId,
        }));
        return addProductAssistantApi(productArray);
      }),
      getAssistantCasesApi(row.id).then((res) => {
        const caseArray = res.data.map((item) => ({
          case_id: item.case_id,
          assistant_id: assistantId,
        }));
        return addCaseAssistantApi(caseArray);
      }),
    ])
      .then(async (results) => {
        await getAssistantsWxIds();
        Message.success("复制助手成功");
      })
      .catch((error) => {
        console.error("发生错误:", error);
        Message.error("执行失败");
      });
  };

  // 动态生成表格列
  const columns = [
    {
      title: "数字员工",
      dataIndex: "name",
      key: "name",
      render: (name) => <span>{name || " "}</span>,
    },
    {
      title: "发布账号",
      dataIndex: "nickname",
      key: "nickname",
      render: (nickname, row) => (
        <Space>
          <IconWechat style={{ color: "#00B42A" }} />
          <span
            style={{
              color:
                row.is_online || row?.wechats[0]?.is_online
                  ? "black"
                  : "#C9CDD4",
            }}
          >
            {row.assistant === "" ? row.wechats[0].nickname : nickname}
          </span>
        </Space>
      ),
    },
    {
      title: "自动回复",
      dataIndex: "auto_reply",
      key: "auto_reply",
      render: (autoReply, record) => (
        <Switch
          checked={autoReply}
          disabled={!record.wx_id || !record.is_online} // 如果没有绑定微信，禁用开关
          onChange={(checked) => handleSwitchChange(record.wx_id, checked)}
        />
      ),
    },
    {
      title: "编辑",
      dataIndex: "edit",
      key: "edit",
      render: (_, row) => (
        <Button
          style={{ marginLeft: "-15px" }}
          type="text"
          disabled={row.assistant === ""}
          onClick={() => handleSelectedEmployee(row)}
        >
          编辑
        </Button>
      ),
    },
    {
      title: "复制",
      dataIndex: "copy",
      key: "copy",
      render: (_, row) => (
        <Button
          type="text"
          style={{ marginLeft: "-15px" }}
          disabled={row.assistant === ""}
          onClick={() => handleCopy(row)}
        >
          复制
        </Button>
      ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      render: (_, row) =>
        row.wx_id ? (
          row.is_online ? (
            <Button
              type="text"
              style={{ marginLeft: "-15px" }}
              disabled={row.assistant === ""}
              onClick={() => {
                confirmLogout(row.wx_id);
              }}
            >
              退出
            </Button>
          ) : (
            <Popconfirm
              focusLock
              title=""
              icon={null}
              content={
                <div
                  style={{
                    textAlign: "center",
                    width: "200px",
                    height: "220px",
                  }}
                >
                  <h3>使用微信扫码登录帐号接入</h3>
                  {qrCode && (
                    <>
                      <QRCodeSVG
                        value={qrCode} // 生成二维码的内容
                        size={150}
                      />
                    </>
                  )}
                </div>
              }
              footer={null}
              onOk={cancelLogin}
              onCancel={cancelLogin}
            >
              <Button
                type="outline"
                style={{ marginLeft: "-15px" }}
                onClick={handleLogin}
              >
                登录
              </Button>
            </Popconfirm>
          )
        ) : (
          <Button
            type="text"
            status="danger"
            style={{ marginLeft: "-15px" }}
            disabled={row.assistant === ""}
            onClick={() => handleDeleteAssistant(row.id)} // 调用删除函数
          >
            删除
          </Button>
        ),
    },
  ];

  return (
    <div style={{ width: "60vw" }}>
      <Table
        columns={columns}
        data={employeeList}
        rowKey={(record) => record.wx_id || record.id} // 如果没有微信账号，使用员工 ID 作为 key
        pagination={false}
      />
      <div style={{ marginTop: "10px", cursor: "pointer" }}>
        <Button
          type="text"
          onClick={handleAddEmployee}
          disabled={employeeList.length >= maxSeats}
        >
          添加员工
        </Button>
        <span style={{ float: "right" }}>
          剩余席位 {maxSeats - employeeList.length} 个
        </span>
      </div>
    </div>
  );
};

export default EmployeeTable;
