// ProductList.jsx
import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Message, Space } from "@arco-design/web-react";
import {
    addProductApi,
    updateProductApi,
    getProductsApi,
    deleteProductApi,
    getAssistantProductsApi,
    addProductAssistantApi, // Import the assistant-specific API
} from "../../../api/normalApi";
import PropTypes from "prop-types";
import ProductCardModal from "./ProductCardModal";
import ProductListModal from "./ProductListModal";

const ProductList = ({
  columns,
  tableWidth,
  assistant_id,
  setProducts,
  mode,
}) => {
  // Accept assistant_id as a prop
  const [productList, setProductList] = useState([]);
  const [editableRow, setEditableRow] = useState(null);
  const [addDisabled, setAddDisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // 控制对话框可见性
  const editMode = useRef("");
  const [product, setProduct] = useState([]); //传递过去的商品
  const [newProduct, setNewProduct] = useState();
  const [productListVisible, setProductListVisible] = useState(false); //勾选对话是否可见

  useEffect(() => {
    if (!productListVisible) {
      getProducts();
    }
    // eslint-disable-next-line
  }, [productListVisible]);

  // 获取商品列表
  const getProducts = async () => {
    try {
      let data;

      if (assistant_id) {
        // Fetch assistant-specific products
        const response = await getAssistantProductsApi(assistant_id);
        if (response.status !== "success") {
          throw new Error("Failed to fetch assistant products");
        }
        // Extract the nested product objects
        data = response.data.map((item) => item.product);
        // console.log(data)

        const filteredProducts = data.map((item) => ({
          name: item.name,
          price: item.price,
          description: item.description,
          urls: item.urls,
        }));

        setProducts(filteredProducts);
      } else {
        // Fetch all products
        const response = await getProductsApi();
        if (response.status !== "success") {
          throw new Error("Failed to fetch products");
        }
        data = response.data;
      }

      const productsWithKey = data.map((item, index) => ({
        ...item,
        key: assistant_id ? item.id : index + 1, // Use product.id as key if assistant_id is present
        isNew: false,
      }));
      setProductList(productsWithKey);
    } catch (error) {
      Message.error("获取商品列表失败，请稍后重试");
      console.error(error);
    }
  };

  // 新增行
  const handleAdd = () => {
    setModalVisible(true);
    editMode.current = "add";
    setProduct([]);
  };

  const handleDelete = () => {
    if (product && product.id) {
      deleteProductApi(product.id).then((data) => {
        if (data.status === "success") {
          Message.success("商品删除成功");
          setModalVisible(false);
          getProducts();
        } else {
          Message.error("商品删除失败");
          console.log(data);
          setModalVisible(false);
        }
      });
    }
  };

  // 编辑行
  const handleEdit = (record) => {
    editMode.current = "edit";
    setProduct({ ...record });
    setModalVisible(true);
  };

  // 保存行
  const handleSave = async () => {
    if (newProduct) {
      if (
        !newProduct.name ||
        newProduct.price <= 0 ||
        !newProduct.description ||
        !newProduct.urls
      ) {
        Message.error("请完善商品信息！");
        editMode.current = "";
        return; // Prevent further execution if validation fails
      }
    }

    if (editMode.current === "add") {
      addProductApi({ ...newProduct }).then((response) => {
        if (response.status === "success") {
          // 如果有 assistant_id，则调用绑定商品和助手api
          if (assistant_id) {
              console.log(response.data.id)
            const data = [{
                product_id: response.data.id,
                assistant_id: assistant_id,
            }];
              addProductAssistantApi(data).then((bindResponse) => {
              if (bindResponse.status !== "success") {
                Message.error("绑定商品和助手失败");
              } else {
                getProducts().then(() => {
                  Message.success("新增商品成功");
                });
              }
            });
          } else {
            getProducts().then(() => {
              Message.success("新增商品成功");
            });
          }
        } else {
          Message.error("新增商品失败");
        }
      });
    } else if (editMode.current === "edit") {
      // 编辑商品
      const response = await updateProductApi(newProduct.id, { ...newProduct });
      if (response.status === "success") {
        await getProducts();
        Message.success("修改商品成功");
      } else {
        Message.error("修改商品失败");
      }
    }

    setEditableRow(null);
    setAddDisabled(false);
  };

  // 处理字段变化
  const handleFieldChange = (value, key, field) => {
    const newData = productList.map((item) => {
      if (item.key === key) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setProductList(newData);
  };

  // 定义所有可能的列
  const allColumns = [
    {
      title: "编号",
      dataIndex: "key",
      width: 70,
    },
    {
      title: (
        <Space>
          商品名称
          {mode === "displayModal" && (
            <Button type="text" onClick={() => setProductListVisible(true)}>
              管理
            </Button>
          )}
        </Space>
      ),
      dataIndex: "name",
      render: (text, record) => {
        return editableRow === record.key ? (
          <Input
            value={record.name}
            onChange={(value) => handleFieldChange(value, record.key, "name")}
          />
        ) : (
          text
        );
      },
      width: 180,
    },
    {
      title: "价格",
      dataIndex: "price",
      render: (text, record) => {
        return editableRow === record.key ? (
          <Input
            value={record.price}
            onChange={(value) => handleFieldChange(value, record.key, "price")}
          />
        ) : (
            text+"元"
        );
      },
      width: 100,
    },
    {
      title: "商品介绍",
      dataIndex: "description",
      render: (text, record) => {
        return editableRow === record.key ? (
          <Input
            value={record.description}
            onChange={(value) =>
              handleFieldChange(value, record.key, "description")
            }
          />
        ) : (
          text
        );
      },
    },
    {
      title: "访问链接",
      dataIndex: "urls",
      render: (text, record) => {
        return editableRow === record.key ? (
          <Input
            value={record.urls}
            onChange={(value) => handleFieldChange(value, record.key, "urls")}
          />
        ) : (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text || "暂无链接"}
          </a>
        );
      },
    },
    {
      title: (
        <Button
          size={"small"}
          type="primary"
          onClick={handleAdd}
          disabled={addDisabled}
        >
          新建
        </Button>
      ),
      dataIndex: "actions",
      width: 80,
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              handleEdit(record);
            }}
            size={"small"}
          >
            编辑
          </Button>
        );
      },
    },
  ];

  // 根据传入的 columns prop 过滤要显示的列，如果未传入，则显示所有列
  const displayedColumns = columns
    ? allColumns.filter((col) => columns.includes(col.dataIndex))
    : allColumns;

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, [assistant_id]);

  useEffect(() => {
    if (newProduct) {
      handleSave();
    }
    // eslint-disable-next-line
  }, [newProduct]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ProductListModal
        setProducts={setProducts}
        productListVisible={productListVisible}
        setProductListVisible={setProductListVisible}
        assistant_id={assistant_id}
      />
      <Table
        size={"small"}
        columns={displayedColumns}
        data={productList}
        pagination={false}
        style={{ minWidth: tableWidth }}
      />
      <ProductCardModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        product={product}
        setProduct={setNewProduct}
        handleDelete={handleDelete}
        assistant_id={assistant_id}
      />
    </Space>
  );
};

// 定义 propTypes
ProductList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  tableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assistant_id: PropTypes.string, // Add assistant_id to propTypes
  mode: PropTypes.oneOf(["displayModal", "normal"]),
};

export default ProductList;
