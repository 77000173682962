// src/AppRouter.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import MainLayout from "./pages/MainLayout"; // 新的布局组件
import Messages from "./pages/MessageList/Messages";
import AgentList from "./pages/AgentList/AgentList";
import SalesMaterial from "./pages/SalesMaterial/SalesMaterial";

const PrivateRoute = ({ children }) => {
  checkLocalStorage();
  const token = localStorage.getItem("sales_token");
  return token ? children : <Navigate to="/login" />;
};

// 检查localStorage是不是满了
const checkLocalStorage = () => {
  try {
    // 尝试存储一个临时值以检测 localStorage 是否满了
    localStorage.setItem("storage_test", "test");
    localStorage.removeItem("storage_test");
    return true;
  } catch (e) {
    if (
      e.name === "QuotaExceededError" ||
      e.name === "NS_ERROR_DOM_QUOTA_REACHED"
    ) {
      console.warn("LocalStorage is full, clearing all data.");
      localStorage.clear();
      return false;
    } else {
      console.error("An unexpected error occurred with localStorage:", e);
      return false;
    }
  }
};

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          {/* 默认子路由重定向到 /messages */}
          <Route index element={<Navigate to="/messages" replace />} />
          <Route path="messages" element={<Messages />} />
          <Route path="employees" element={<AgentList />}>
            {/* 添加编辑员工的嵌套路由 */}
            <Route path=":id" element={<AgentList />} />
          </Route>
          <Route path="files" element={<SalesMaterial />} />
          {/* 其他子路由可以在这里添加 */}
        </Route>
        {/* 处理 404 页面 */}
        <Route path="*" element={<Navigate to="/messages" replace />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
