import React, { useEffect, useRef, useState } from "react";
import {
    Input,
    Button,
    Space,
    Message,
} from "@arco-design/web-react";
import {
    updateAssistantApi,
    getAssistantsIdApi,
} from "../../../api/normalApi";
import ProductList from "../../SalesMaterial/components/ProductList";
import CaseMaterial from "../../SalesMaterial/components/CaseMaterial";
import ChatTest from "./ChatTest";

const { TextArea } = Input;

const EditPage = ({
    draftInstructions,
    setDraftInstructions,
    roleInstructions,
    setRoleInstructions,
    selectedEmployee,
    setShowPart,
    setSalesProcess,
    salesProcess,
}) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState("新员工");
    const [products, setProducts] = useState(null);
    const [cases, setCases] = useState(null);
    const [scripts, setScripts] = useState([]);
    const inputRef = useRef(null);
    // eslint-disable-next-line
    const [chatMode, setChatMode] = useState(
        localStorage.getItem("chat_mode") || "sales",
    );

    useEffect(() => {
        updateAssistant()
        // eslint-disable-next-line
    }, [cases, products])

    // 获取员工数据并更新状态
    const fetchAssistantData = async (id) => {
        try {
            const response = await getAssistantsIdApi(id);
            if (response.status === "success") {
                const data = response.data;
                setName(data.name);
                setDraftInstructions(data.draft_instructions);
                setRoleInstructions(data.role_instructions);
                setSalesProcess(data.sales_process);
            } else {
                Message.error("获取员工信息失败");
            }
        } catch (error) {
            Message.error("请求失败，请重试");
            console.error(error);
        }
    };

    // 获取员工信息和产品列表
    useEffect(() => {
        if (selectedEmployee) {
            fetchAssistantData(selectedEmployee.id);
        }
        // eslint-disable-next-line
    }, [selectedEmployee]);

    // 保存名称
    const handleSaveName = () => {
        if (!name.trim()) {
            Message.error("员工名不能为空");
            return;
        }
        updateAssistantApi(selectedEmployee.id, { name }).then(async (data) => {
            if (data.status === "success") {
                Message.success("名称已修改");
                fetchAssistantData(selectedEmployee.id);
            } else {
                Message.error("修改失败");
                console.log(data);
            }
        });
        setEditing(false);
    };

    // 聚焦输入框
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const updateAssistant = async () => {
        // 检查 products 和 cases 是否为 null
        if (!products || !cases) {
            // console.log("products 或 cases 为 null，无法更新角色信息和销售流程");
            return;
        }

        // 构建新的 draftInstructions
        const productInstructions = products
            .map((item) => {
                return `${item.name} ${item.price}元 ${item.description} ${item.urls}`;
            })
            .join("\n");

        // console.log(cases)
        const casesInstructions = cases
            .map((item) => {
                return `${item.case_name} ${item.description} https://zchat-1256349444.cos.ap-guangzhou.myqcloud.com/${item.file_url}`;
            })
            .join("\n");

        // 初始化 newDraftInstructions
        let newDraftInstructions = `${roleInstructions}\n\n`;

        // 仅当 productInstructions 不为空时添加相关内容
        if (productInstructions.trim() !== "") {
            newDraftInstructions += `我们的产品、价格、介绍以及链接如下：\n${productInstructions}\n\n`;
        }

        // 仅当 casesInstructions 不为空时添加相关内容
        if (casesInstructions.trim() !== "") {
            newDraftInstructions += `我们的销售资料名称、介绍、文件链接如下：\n${casesInstructions}\n\n`;
        }

        // 始终添加销售流程话术参考
        // newDraftInstructions += `产品销售流程话术参考：\n${salesProcess}`;

        setDraftInstructions(newDraftInstructions);
        // console.log(newDraftInstructions)

        const newEmployee = {
            draft_instructions: newDraftInstructions,
            role_instructions: roleInstructions,
            sales_process: salesProcess,
        };

        // console.log(newDraftInstructions);

        // 更新助理信息
        await updateAssistantApi(selectedEmployee.id, newEmployee);
    };

    // 解析销售流程字符串到脚本数组
    useEffect(() => {
        if (salesProcess) {
            const regex =
                /\*阶段\*\s*(.*?)\s*\*描述\*\s*(.*?)\s*\*参考话术\*\s*(.*?)(?=\*阶段\*|$)/gs;
            let match;
            const parsedScripts = [];
            while ((match = regex.exec(salesProcess)) !== null) {
                parsedScripts.push({
                    stage: match[1].trim(),
                    description: match[2].trim(),
                    script: match[3].trim(),
                });
            }
            // 如果没有匹配到任何脚本，初始化一个默认空脚本
            if (parsedScripts.length === 0) {
                parsedScripts.push({ stage: "", description: "", script: "" });
            }
            setScripts(parsedScripts);
        } else {
            // 如果 salesProcess 为空，也初始化一个默认空脚本
            setScripts([{ stage: "", description: "", script: "" }]);
        }
    }, [salesProcess]);

    // 将脚本数组转换为保存格式
    const formatSalesProcess = () => {
        return scripts
            .map((script) => {
                return `*阶段* ${script.stage}\n*描述* ${script.description}\n*参考话术* ${script.script}`;
            })
            .join("\n\n");
    };

    // 添加新脚本（在指定索引下方插入）
    // const handleAddScriptAtIndex = (index) => {
    //     const newScript = { stage: "", description: "", script: "" };
    //     const newScripts = [...scripts];
    //     newScripts.splice(index + 1, 0, newScript);
    //     setScripts(newScripts);
    // };
    //
    // // 删除脚本
    // const handleRemoveScript = (index) => {
    //     const newScripts = scripts.filter((_, i) => i !== index);
    //     // 确保至少有一个脚本存在
    //     if (newScripts.length === 0) {
    //         setScripts([{ stage: "", description: "", script: "" }]);
    //     } else {
    //         setScripts(newScripts);
    //     }
    // };

    // 保存脚本到后端（防抖处理）
    useEffect(() => {
        if (selectedEmployee) {
            const timeout = setTimeout(() => {
                const formattedProcess = formatSalesProcess();
                setSalesProcess(formattedProcess);
                updateAssistant();
            }, 500); // 500ms 防抖

            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line
    }, [scripts]);

    // const changeReplyMode = (value) => {
    //     setChatMode(value);
    //     localStorage.setItem("chat_mode", value);
    // };

    return (
        <Space style={{
            height: "100vh", paddingRight: 8
        }} align={"start"}>
            <Space
                direction={"vertical"}
                style={{
                    height: "100vh",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#EEE white",
                    // overflowY: "auto",
                    width: "33vw"
                }}
            >
                <Space
                    style={{
                        width: "100%",
                        height: 40,
                        marginTop: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h3 style={{ margin: 0, marginRight: 10 }}>员工信息</h3>
                    <Input
                        ref={inputRef}
                        value={name}
                        onChange={setName}
                        onBlur={handleSaveName}
                        onPressEnter={handleSaveName}
                        placeholder="未命名的新员工"
                        style={{ flex: 1, marginRight: 10, minWidth: "10vw", textAlign: "center" }}
                    />
                    <Button
                        size="small"
                        type="outline"
                        onClick={() => setShowPart(false)}
                    >
                        返回
                    </Button>
                </Space>
                <TextArea
                    style={{
                        maxHeight: "94vh",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                        // overflowY: "auto",
                    }}
                    placeholder={"填写人物设定"}
                    autoSize={{ minRows: 10 }}
                    value={roleInstructions}
                    onChange={(v) => {
                        setRoleInstructions(v);
                    }}
                    onBlur={updateAssistant}
                />

            </Space>
            <ChatTest
                draftInstructions={draftInstructions}
                selectedEmployee={selectedEmployee}
                chatMode={chatMode}
            />
            <Space direction={"vertical"}>
                <Space
                    style={{
                        width: "100%",
                        height: 40,
                        marginTop: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h3 style={{ margin: 0, marginRight: 10 }}>销售资料</h3>
                </Space>
                {selectedEmployee && (
                    <div style={{ marginTop: "-25px" }}>
                        <ProductList
                            columns={["name", "price", "actions"]}
                            assistant_id={selectedEmployee.id}
                            products={products}
                            setProducts={setProducts}
                            mode={"displayModal"}
                        />
                        <CaseMaterial
                            columns={["case_name", "description", "actions"]}
                            assistant_id={selectedEmployee.id}
                            cases={cases}
                            setCases={setCases}
                            mode={"displayModal"}
                        />
                    </div>
                )}
            </Space>
        </Space>
    );
};

export default EditPage;
