// utils/format.js
export const formatTimestampToTime = (timestamp) => {
    // 将 UNIX 时间戳转换为毫秒，并创建 Date 对象
    const date = new Date(timestamp * 1000);
    // 获取时分秒，并确保它们是两位数格式
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const formatTimestampToDateTime = (timestamp) => {
    // 将时间戳转换为毫秒
    const date = new Date(timestamp * 1000);

    // 获取年份、月份、日期、小时、分钟和秒
    const year = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // 拼接成所需格式
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}

export const formatTimestampToDate = (timestamp) => {
    // 将时间戳转换为毫秒
    const date = new Date(timestamp * 1000);
    const year = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    // 拼接成所需格式
    return `${year}/${month}/${day}`;
}

export const isToday = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const today = new Date();

    // 判断日期是否相同
    const isSameDay = date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();

    // 根据判断结果调用相应的格式化函数
    return isSameDay ? formatTimestampToTime(timestamp) : formatTimestampToDate(timestamp);
};