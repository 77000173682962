import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Input, Message, Space, Form } from "@arco-design/web-react";
import { login } from "../api/normalApi";

const { Item: FormItem } = Form; // 确保 FormItem 的别名设置正确

function Login() {
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        const { account, password } = values;
        try {
            login(account, password).then((response) => {
                localStorage.setItem('sales_token', response.data.token);
                const userInfo = {
                    "username": response.data.username,
                    "user_id": response.data.user_id
                }
                localStorage.setItem('user_info', JSON.stringify(userInfo));
                navigate('/home');
            });

            // Message.success('登录成功');
        } catch (error) {
            console.error('Login error:', error);
            Message.warning('用户名和密码错误');
        }
    };

    const onReset = () => {
        Message.info('重置成功');
    };

    // 动态设置标题名称
    const title = window.location.hostname === 'zybke.com' ? '智赢百客' : '智话销售';

    return (
        <Space style={{ width: "100vw", height: "100vh" }}>
            <Card style={{
                width: '300px',
                marginLeft: "calc(50vw - 175px)",
                marginBottom: "calc(30vh - 175px)",
                padding: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '8px',
            }}>
                <Space direction={'vertical'} align={'center'} style={{ width: '100%', height: '100%' }}>
                    <Space style={{ textAlign: "center" }}>
                        <img style={{ width: "30px", marginLeft: -10, marginTop: 10, marginRight: 5 }}
                             src={`${process.env.PUBLIC_URL}/favicon.svg`} alt="logo" />
                        <h2 style={{ color: "#3491FA" }}>{title}</h2>
                    </Space>
                    <Form onSubmit={onSubmit} onReset={onReset} colon={true} layout={'vertical'}>
                        <FormItem field="account" rules={[{ required: true }]}>
                            <Input clearable={true} placeholder="请输入账户名" />
                        </FormItem>
                        <FormItem field="password" rules={[{ required: true }]}>
                            <Input.Password placeholder={'请输入密码'} />
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                登录
                            </Button>
                        </FormItem>
                    </Form>
                </Space>
            </Card>
        </Space>
    );
}

export default Login;