import React, { useEffect, useState } from "react";
import ChatList from "./Messages/ChatList";
import AssistantInfo from "./AssistantInfo/AssistantInfo";
import { Avatar, Space, Spin, Tabs } from "@arco-design/web-react";
import { getWxAccountsApi, getAssistantByWxIDApi } from "../../api/normalApi";

const Messages = () => {
  const [wxAccountList, setWxAccountList] = useState([]);
  const [currentWxAccount, setCurrentWxAccount] = useState(
    (() => {
      const storedData = localStorage.getItem("currentWxAccount");
      return storedData && storedData !== "undefined"
        ? JSON.parse(storedData)
        : null;
    })(),
  );
  const [currentAssistant, setCurrentAssistant] = useState(null);
  const [loadingAssistant, setLoadingAssistant] = useState(false); // 状态来控制加载
  const [threadId, setThreadId] = useState(null); //传给accountInfo获取background

  const fetchAccounts = async () => {
    try {
      const response = await getWxAccountsApi();
      if (response.status === 200) {
        setWxAccountList(response.data);
        if (response.data.length > 0) {
          if (currentWxAccount === null) {
            localStorage.setItem(
              "currentWxAccount",
              JSON.stringify(response.data[0]),
            );
            setCurrentWxAccount(response.data[0]);
            fetchAssistant(response.data[0].wx_user_id); // 获取第一个账号的 Assistant
          } else {
            fetchAssistant(currentWxAccount.wx_user_id);
          }
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchAssistant = async (wx_id) => {
    setLoadingAssistant(true); // 开始加载时设置为 true
    try {
      await getAssistantByWxIDApi(wx_id).then((response) => {
        if (response.status === "success" && response.data) {
          setCurrentAssistant(response.data);
          setLoadingAssistant(false);
        } else {
          setCurrentAssistant(null);
          setLoadingAssistant(false);
        }
      });
    } catch (error) {
      console.error("API Error:", error);
      setCurrentAssistant(null);
    }
  };

  // Fetch WeChat accounts on component mount
  useEffect(() => {
    fetchAccounts();
  }, []);

  const handleTabChange = (v) => {
    const foundAccount = wxAccountList.find(
      (account) => account.wx_user_id === v,
    );
    setCurrentWxAccount(foundAccount);
    try {
      localStorage.setItem("currentWxAccount", JSON.stringify(foundAccount));
    }
    catch (error) {
      console.error("localStorage Error:", error);
    }
    if (foundAccount) {
      fetchAssistant(foundAccount.wx_user_id); // 根据选中的 wx_id 获取 Assistant
    } else {
      setCurrentAssistant(null);
    }
  };

  return (
    <Space align="start">
      <Space style={{ boxShadow: "3px 0 2px rgba(0, 0, 0, 0.03)" }}>
        {wxAccountList.length > 0 && (
          <Tabs
            onChange={handleTabChange}
            type="text"
            activeTab={currentWxAccount ? currentWxAccount.wx_user_id : null} // Set the default active tab
            style={{ marginTop: 8, width: "calc(43vw + 250px)" }}
          >
            {wxAccountList.map((account) => (
              <Tabs.TabPane
                key={account.wx_user_id}
                title={
                  <Space>
                    <Avatar
                      shape="square"
                      size={30}
                      style={{ backgroundColor: "#3370ff" }}
                    >
                      {account.head_img ? (
                        <img
                          alt="头像"
                          src={`data:image/png;base64,${account.head_img}`}
                        />
                      ) : (
                        account.wx_nickname.charAt(0)
                      )}
                    </Avatar>
                    {account.wx_nickname}
                  </Space>
                }
              >
                {currentWxAccount &&
                  currentWxAccount.wx_user_id === account.wx_user_id && (
                    <ChatList
                      currentWxAccount={currentWxAccount}
                      setThreadId={setThreadId}
                      assistant={currentAssistant}
                    />
                  )}
              </Tabs.TabPane>
            ))}
          </Tabs>
        )}
      </Space>

      {/* 只有当加载完成并且有Assistant数据时才渲染AssistantInfo */}
      {loadingAssistant ? (
        <Space align="center" style={{ width: "100%", height: "100vh" }}>
          <Spin dot />
        </Space>
      ) : (
        currentAssistant && (
          <AssistantInfo assistant={currentAssistant} threadId={threadId} />
        )
      )}
    </Space>
  );
};

export default Messages;
