import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Message,
  Modal,
  Space,
  Tag,
} from "@arco-design/web-react";
import { IconWechat } from "@arco-design/web-react/icon";
import "@arco-design/web-react/dist/css/arco.css";
import {
  bindAgentApi,
  cancelWechatApi,
  getAssistantsIdApi,
  getAssistantsWithWeChatApi,
  getWeChats,
  loginWechatApi,
  unBindAgentApi,
  updateAssistantApi,
} from "../../../../../api/normalApi";
import { QRCodeSVG } from "qrcode.react";

const statusColorMap = {
  未登录: "blue",
  登录中: "orange",
  需要登录: "#FF7D00",
  准备就绪: "green",
  已绑定: "blue",
};

const LaunchModal = ({ selectedEmployee, draftInstructions, chatMode }) => {
  // 状态声明
  const [visible, setVisible] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [intervalActive, setIntervalActive] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [qrCode, setQrCode] = useState(null);
  const [accountCount, setAccountCount] = useState(1); // 用于生成唯一的账户 ID
  const [isLoggingIn, setIsLoggingIn] = useState(false); // 标识是否有登录正在进行
  const [currentLoggingAccountId, setCurrentLoggingAccountId] = useState(null); // 当前登录的账户 ID
  const [previousAccountCount, setPreviousAccountCount] = useState(0);
  const [previousUpdatedAt, setPreviousUpdatedAt] = useState({}); // 存储每个账号的 updated_at
  const [wxInstanceName, setWxInstanceName] = useState(); //登陆新的微信账户返回的实例
  const [assistant, setAssistant] = useState(null); //初始化获取assistant的信息

  const salesMode = useRef(0);

  useEffect(() => {
    if (chatMode === "service") {
      salesMode.current = 0;
    } else if (chatMode === "sales") {
      salesMode.current = 1;
    }
  }, [chatMode]);

  // 获取所有微信账户并检测登录状态，并且匹配更新 '已绑定' 状态
  const getAllWxAccounts = useCallback(async () => {
    try {
      // 获取所有微信账户
      const weChatData = await getWeChats();

      if (weChatData && weChatData.data) {
        // 创建临时变量 tempAccounts 来存储初始账户
        let tempAccounts = [];

        // 将 fetchedAccounts 中的账户添加到 tempAccounts
        const fetchedAccounts = weChatData.data.map((account) => ({
          ...account,
          id: account.wx_id,
          label: account.nickname,
          platform: "wechat",
          status: account.is_online === true ? "准备就绪" : "需要登录",
          statusColor:
            account.is_online === true
              ? statusColorMap["准备就绪"]
              : statusColorMap["需要登录"],
          isNew: false, // 已存在的账户
          updated_at: account.updated_at, // 确保API返回updated_at
        }));

        // 将准备就绪的账户和 isNew: true 的账户合并
        tempAccounts = [
          ...fetchedAccounts,
          ...tempAccounts.filter((a) => a.isNew), // 保留所有 isNew: true 的账户（待登录的占位账户）
        ];

        // 获取员工及微信账号信息，更新 '已绑定' 状态
        const assistantsData = await getAssistantsWithWeChatApi(); // 调用新接口获取数据
        if (assistantsData && assistantsData.data) {
          // 遍历 tempAccounts 并更新匹配到的账户状态为 '已绑定'
          tempAccounts = tempAccounts.map((account) => {
            // 查找是否有 assistant.id 匹配 selectedEmployee.id
            const matchingAssistant = assistantsData.data.find(
              (item) => item.assistant.id === selectedEmployee.id,
            );

            if (
              matchingAssistant &&
              matchingAssistant.wechats.some((wx) => wx.wx_id === account.id)
            ) {
              // 如果找到匹配的账号，将状态更新为 '已绑定'
              return {
                ...account,
                status: "已绑定",
                assistant: matchingAssistant.assistant.name,
              };
            }

            // 没有匹配则保持原状态
            return account;
          });
        }

        // 统一调用 setAccounts 更新账户状态
        setAccounts(tempAccounts);

        // 检查登录是否成功
        if (isLoggingIn && currentLoggingAccountId) {
          const currentCount = weChatData.data.length;
          // 判断是否有新账号添加（账号数量增加）
          if (currentCount > previousAccountCount) {
            // 登录成功，账号数量增加
            setIntervalActive(false);
            setQrCode(null);
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null);
            setPreviousAccountCount(currentCount);
            setPreviousUpdatedAt(
              weChatData.data.reduce((acc, account) => {
                acc[account.wx_id] = account.updated_at;
                return acc;
              }, {}),
            );
            Message.success("登录成功");
            return;
          }

          // 判断是否有现有账号的 updated_at 变化
          const updatedAtChanged = weChatData.data.some((account) => {
            const previous = previousUpdatedAt[account.wx_id];
            return previous && previous !== account.updated_at;
          });

          if (updatedAtChanged) {
            // 登录成功，已有账号的 updated_at 发生变化
            setIntervalActive(false);
            setQrCode(null);
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null);
            setPreviousAccountCount(currentCount);
            setPreviousUpdatedAt(
              weChatData.data.reduce((acc, account) => {
                acc[account.wx_id] = account.updated_at;
                return acc;
              }, {}),
            );

            // 移除所有 isNew: true 的占位账户
            setAccounts((prevAccounts) =>
              prevAccounts.filter((account) => !account.isNew),
            );

            Message.success("登录成功");
          }
        } else {
          // 更新前一次的账户列表和计数
          setPreviousAccountCount(weChatData.data.length);
          setPreviousUpdatedAt(
            weChatData.data.reduce((acc, account) => {
              acc[account.wx_id] = account.updated_at;
              return acc;
            }, {}),
          );
        }
      }
    } catch (err) {
      console.log("获取账户失败:", err);
      Message.error("获取账户失败，请稍后重试");
    }
    // eslint-disable-next-line
  }, [
    isLoggingIn,
    currentLoggingAccountId,
    previousAccountCount,
    previousUpdatedAt,
  ]);

  // 打开模态框并获取账户
  const handleOpen = useCallback(async () => {
    setVisible(true);
    await getAllWxAccounts();
  }, [getAllWxAccounts]);

  // 关闭模态框并清理相关状态
  const handleClose = useCallback(() => {
    setVisible(false);
    setSelectedAccounts([]);
    setIntervalActive(false); // 停止定时器
    setQrCode(null); // 隐藏二维码
    setIsLoggingIn(false); // 重置登录状态
    setCurrentLoggingAccountId(null); // 重置当前登录账户 ID

    // 过滤掉“未登录”和“登录中”的账户
    setAccounts((prevAccounts) =>
      prevAccounts.filter((account) => account.status === "准备就绪"),
    );
    setPreviousAccountCount(0);
    setPreviousUpdatedAt({});
  }, []);

  // 处理账户选择
  const handleAccountChange = useCallback((checkedValues) => {
    setSelectedAccounts(checkedValues);
  }, []);

  // 添加账户并直接启动登录流程
  const addAccounts = useCallback(async () => {
    if (isLoggingIn) {
      Message.warning("登录过程中无法添加新账号");
      return;
    }

    if (accounts.length >= 5) {
      Message.warning("最多只能添加5个账号");
      return;
    }

    const newId = `account${accountCount + 1}`;
    const newAccount = {
      id: newId,
      label: "个人微信",
      platform: "wechat",
      status: "登录中",
      statusColor: statusColorMap["登录中"],
      isNew: true, // 标识为新添加的账户
    };
    setAccounts((prevState) => [...prevState, newAccount]);
    setAccountCount((prevCount) => prevCount + 1);

    // 开始登录流程
    setIsLoggingIn(true);
    setCurrentLoggingAccountId(newId);

    try {
      const data = await loginWechatApi();
      if (data && data.data && data.data.qr_code) {
        setQrCode(data.data.qr_code);
        setWxInstanceName(data.data.wx_instance_name);
        setIntervalActive(true); // 启动轮询
      } else {
        console.log("没有获取到登录数据");
        Message.error("无法获取二维码，请重试");
        // 移除新添加的账户
        setAccounts((prevAccounts) =>
          prevAccounts.filter((a) => a.id !== newId),
        );
        setIsLoggingIn(false);
        setCurrentLoggingAccountId(null);
      }
    } catch (err) {
      console.log(err);
      Message.error("登录失败，请重试");
      // 移除新添加的账户
      setAccounts((prevAccounts) => prevAccounts.filter((a) => a.id !== newId));
      setIsLoggingIn(false);
      setCurrentLoggingAccountId(null);
    }
  }, [accounts.length, accountCount, isLoggingIn]);

  // 处理登录点击（用于现有未登录账户）
  const handleLoginClick = useCallback(
    async (accountId) => {
      if (isLoggingIn) {
        Message.warning("当前已有账号正在登录，请等待完成");
        return;
      }

      setIsLoggingIn(true); // 设置登录状态为进行中
      setCurrentLoggingAccountId(accountId); // 记录当前登录的账户

      setAccounts((prevAccounts) => {
        return prevAccounts.map((account) => {
          if (account.status === "登录中" && account.id !== accountId) {
            return {
              ...account,
              status: "未登录",
              statusColor: statusColorMap["未登录"],
            };
          } else if (account.id === accountId) {
            return {
              ...account,
              status: "登录中",
              statusColor: statusColorMap["登录中"],
            };
          }
          return account;
        });
      });

      try {
        const data = await loginWechatApi();
        if (data && data.data && data.data.qr_code) {
          setQrCode(data.data.qr_code);
          setIntervalActive(true);
          setWxInstanceName(data.data.wx_instance_name);
        } else {
          console.log("没有获取到登录数据");
          Message.error("无法获取二维码，请重试");
          // 恢复状态
          setAccounts((prevAccounts) =>
            prevAccounts.map((account) => {
              if (account.id === accountId) {
                return {
                  ...account,
                  status: "未登录",
                  statusColor: statusColorMap["未登录"],
                };
              }
              return account;
            }),
          );
          setIsLoggingIn(false);
          setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
        }
      } catch (err) {
        console.log(err);
        Message.error("登录失败，请重试");
        // 恢复状态
        setAccounts((prevAccounts) =>
          prevAccounts.map((account) => {
            if (account.id === accountId) {
              return {
                ...account,
                status: "未登录",
                statusColor: statusColorMap["未登录"],
              };
            }
            return account;
          }),
        );
        setIsLoggingIn(false);
        setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
      }
    },
    [isLoggingIn],
  );

  const cancelLogin = async () => {
    if (wxInstanceName) {
      await cancelWechatApi({ wx_instance_name: wxInstanceName }).then(
        (data) => {
          if (data.status !== 200) {
            console.log(data);
          } else {
            setIntervalActive(false); //不用继续检测登录状态
            setQrCode(null);
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
            setWxInstanceName(null); //清空现在的微信实例
          }
        },
      );
    }
    getAllWxAccounts();
  };

  // 取消登录
  const cancelLogin1 = useCallback(
    async (accountId) => {
      getAllWxAccounts();
      const account = accounts.find((a) => a.id === accountId);
      if (wxInstanceName) {
        await cancelWechatApi({ wx_instance_name: wxInstanceName }).then(
          (data) => {
            if (data.status !== 200) {
              console.log(data);
            }
          },
        );
      }
      if (account && account.isNew) {
        // 删除新添加的占位账户
        setAccounts((prevAccounts) =>
          prevAccounts.filter((a) => a.id !== accountId),
        );
        // 也从已选择的账户中移除
        setSelectedAccounts((prevSelected) =>
          prevSelected.filter((id) => id !== accountId),
        );
      } else {
        // 仅重置状态
        setAccounts((prevAccounts) =>
          prevAccounts.map((a) => {
            if (a.id === accountId) {
              return {
                ...a,
                status: "未登录",
                statusColor: statusColorMap["未登录"],
              };
            }
            return a;
          }),
        );
      }
      setIntervalActive(false); //不用继续检测登录状态
      setQrCode(null);
      setIsLoggingIn(false);
      setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
      setWxInstanceName(null); //清空现在的微信实例
      setPreviousUpdatedAt((prev) => {
        const newMap = { ...prev };
        delete newMap[accountId];
        return newMap;
      });
    },
    // eslint-disable-next-line
    [accounts, wxInstanceName, getAllWxAccounts],
  );

  //解绑agent
  const unBindAgent = async (account) => {
    if (account.status === "已绑定") {
      try {
        const data = await unBindAgentApi({
          wx_user_ids: [account.wx_id],
          assistant_id: account.assistant_id,
        });
        if (data.status === 200) {
          Message.success("解绑成功");
          getAllWxAccounts();
        } else {
          Message.error("解绑失败");
        }
      } catch (err) {
        console.log(err);
        Message.error("绑定失败");
      }
    } else {
      Message.warning("请选择需要解绑的账号");
    }
  };

  //弹出解绑确认框
  const confirmUnbind = (account) => {
    Modal.confirm({
      title: "确认解绑帐号吗？",
      content: (
        <div style={{ textAlign: "center" }}>
          <p>解绑帐号之后才能重新绑定其他助手</p>
        </div>
      ),
      onOk: async () => {
        unBindAgent(account);
      },
    });
  };

  //绑定agent
  const bindAgent = useCallback(async () => {
    if (selectedAccounts.length > 0) {
      try {
        const data = await bindAgentApi({
          wx_user_ids: selectedAccounts,
          assistant_id: selectedEmployee.id,
        });
        if (data.status === 200) {
          let newEmployee = {
            instructions: draftInstructions,
            chat_mode: salesMode.current,
          };
          await updateAssistantApi(selectedEmployee.id, newEmployee).then(
            () => {
              Message.success("绑定成功");
            },
          );
          handleClose();
        } else {
          Message.error("绑定失败");
        }
      } catch (err) {
        console.log(err);
        Message.error("绑定失败");
      }
    } else {
      Message.warning("请选择需要发布的账号");
    }
    // eslint-disable-next-line
  }, [selectedAccounts, selectedEmployee.id]);

  // 设置轮询
  useEffect(() => {
    let intervalId;

    if (intervalActive) {
      intervalId = setInterval(async () => {
        await getAllWxAccounts();
      }, 5000); // 将轮询间隔设置为5秒
    }

    return () => clearInterval(intervalId);
  }, [intervalActive, getAllWxAccounts]);

  // 组件卸载时停止定时器
  useEffect(() => {
    return () => {
      setIntervalActive(false);
    };
  }, []);

  useEffect(() => {
    getAssistantsIdApi(selectedEmployee.id).then((res) => {
      setAssistant(res.data);
    });
  }, [selectedEmployee]);

  return (
    <Space>
      <Button type="outline" onClick={handleOpen} size={"small"}>
        发布
      </Button>
      <Modal
        visible={visible}
        onCancel={handleClose}
        onOk={handleClose}
        footer={
          <Space>
            <Button
              type="outline"
              onClick={addAccounts} // '添加账号' 现在触发登录流程
              disabled={isLoggingIn} // 登录中禁用添加按钮
            >
              添加账号
            </Button>
            <Button
              type="primary"
              onClick={bindAgent}
              disabled={isLoggingIn} // 登录时禁用发布按钮
            >
              发布更新
            </Button>
          </Space>
        }
        title={
          <Space style={{ width: 600, textAlign: "left" }}>
            <h3>发布: {selectedEmployee.name}</h3>
          </Space>
        }
      >
        <Checkbox.Group
          value={selectedAccounts}
          onChange={handleAccountChange}
          style={{ width: "100%" }}
        >
          {accounts.map((account) => (
            <div
              key={account.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                height: 30,
              }}
            >
              <Checkbox
                value={account.id}
                disabled={
                  !(
                    account.status === "准备就绪" ||
                    account.assistant_id === selectedEmployee.id
                  )
                } // 仅允许准备就绪的账户被选择
                style={{ marginRight: "10px" }}
              />
              <IconWechat
                style={{ fontSize: 20, color: "#03DB6C", marginRight: "10px" }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{account.label}</span>
                <div>
                  {account.status === "已绑定" ? (
                    <span style={{ color: "gray", fontSize: 12 }}>
                      已绑定:{account.assistant}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {account.status === "未登录" ||
                  account.status === "登录中" ? (
                    <Button
                      type="outline"
                      size="mini"
                      onClick={() => handleLoginClick(account.id)}
                      disabled={isLoggingIn || account.status === "登录中"} // 禁用按钮以防止重复点击和多次登录
                    >
                      {account.status === "登录中" ? "等待登录" : "登录新账号"}
                    </Button>
                  ) : account.status === "需要登录" ||
                    (account.status === "已绑定" &&
                      account.is_online === false) ? (
                    <Button
                      type="outline"
                      status="warning"
                      size="mini"
                      onClick={() => handleLoginClick(account.id)}
                      disabled={isLoggingIn || account.status === "登录中"} // 禁用按钮以防止重复点击和多次登录
                    >
                      需要登录
                    </Button>
                  ) : account.status === "已绑定" ? (
                    <Button
                      type="outline"
                      size="mini"
                      onClick={() => {
                        confirmUnbind(account);
                      }}
                    >
                      {account.status === "已绑定"
                        ? "解除绑定"
                        : account.status}
                    </Button>
                  ) : (
                    <Tag color={statusColorMap[account.status]} bordered>
                      &nbsp;{account.status}&nbsp;
                    </Tag>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Checkbox.Group>

        {qrCode && (
          <Space
            direction={"vertical"}
            style={{ textAlign: "center", width: "100%" }}
          >
            <h3>使用微信扫码登录帐号接入</h3>
            <QRCodeSVG
              value={qrCode} // 生成二维码的内容
              size={150}
            />
            <Button
              type="primary"
              size="small"
              onClick={cancelLogin}
              style={{ marginTop: "10px" }}
            >
              取消登录
            </Button>
          </Space>
        )}
      </Modal>
    </Space>
  );
};

export default LaunchModal;
