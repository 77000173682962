// AssistantInfo.js
import React, {useEffect, useState} from "react";
import {Space, Tag, Radio, Input, Message, Typography} from "@arco-design/web-react";
import ProductList from "../../SalesMaterial/components/ProductList";
import CaseMaterial from "../../SalesMaterial/components/CaseMaterial";
import {
    getThreadBackgroundApi,
    putThreadBackgroundApi,
} from "../../../api/normalApi";

const TextArea = Input.TextArea;

const AssistantInfo = ({assistant, threadId}) => {
    // console.log(assistant)
    const [products, setProducts] = useState([]);
    const [cases, setCases] = useState([]);
    const [materialTab, setMaterialTab] = useState("用户画像");
    const [roleInfo, setRoleInfo] = useState();
    const [background, setBackground] = useState();

    // const assistantInfo = {
    //     name: "小希老师",
    //     status: {
    //         text: "正在服务",
    //         color: "green",
    //     },
    //     todayReception: 59,
    //     tabs: [
    //         // {
    //         //     key: '3',
    //         //     title: '用户画像',
    //         //     content: null, // Replace with desired content
    //         // },
    //         {
    //             key: '4',
    //             title: '销售流程',
    //             content: <SalesProcess/>
    //         },
    //     ],
    // };

    // 修改当前线程背景信息
    const updateThreadBackground = () => {
        putThreadBackgroundApi(threadId, {background: background}).then((res) => {
            if (res && res.status === "success") {
                Message.success("修改用户画像成功！");
            } else {
                console.log(res);
            }
        });
    };

    useEffect(() => {
        if (threadId) {
            getThreadBackgroundApi(threadId).then((res) => {
                if (res && res.status === "success") {
                    setBackground(res.data.background);
                }
            });
        }
    }, [threadId]);

    useEffect(() => {
        if (assistant && assistant.role_instructions) {
            setRoleInfo(assistant.role_instructions);
        }
    }, [assistant]);

    return (
        <Space direction="vertical" style={{marginTop: 10, marginLeft: 5, marginRight: 5}}>
            <Radio.Group
                type="button"
                name="tab"
                value={materialTab}
                onChange={setMaterialTab}
                options={["用户画像", "角色信息", "销售资料"]}
            >
            </Radio.Group>
            {materialTab === "用户画像" && (
                <TextArea
                    value={background}
                    onChange={(v) => setBackground(v)}
                    onBlur={updateThreadBackground}
                    style={{width: "34vw", height: "90vh"}}
                ></TextArea>
            )}
            {materialTab === "角色信息" && (
                <TextArea
                    value={roleInfo}
                    style={{height: "93vh", width: "40vw"}}
                ></TextArea>
            )}
            {materialTab === "销售资料" && (
                <>
                    <ProductList
                        columns={["name", "price", "description", "actions"]}
                        assistant_id={assistant.id}
                        products={products}
                        setProducts={setProducts}
                    />
                    <CaseMaterial
                        columns={["case_name", "description", "actions"]}
                        assistant_id={assistant.id}
                        cases={cases}
                        setCases={setCases}
                    />
                </>
            )}
        </Space>
    );
};

export default AssistantInfo;
